import { Component, DestroyRef, SecurityContext, inject, type OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { EtagService, RESPONSE } from '@big-direkt/utils/environment';
import { NodeTypes, PlatformService, type NodeModel, type ResolvedRouteData } from '@big-direkt/utils/shared';

@Component({
    selector: 'big-content-component-base',
    template: '',
})
export abstract class ContentBaseComponent implements OnInit {
    private readonly activatedRoute = inject(ActivatedRoute);
    private readonly destroyRef = inject(DestroyRef);
    private readonly platformService = inject(PlatformService);
    private readonly response = inject(RESPONSE, { optional: true });
    private readonly etagService = inject(EtagService);

    public readonly securityContext: SecurityContext = SecurityContext.HTML;
    public readonly nodeTypes: typeof NodeTypes = NodeTypes;

    public node!: NodeModel;

    public get hasIntroduction(): boolean {
        const { node } = this;

        return !!(node.heading ?? node.introduction ?? node.publicationDate ?? node.publicationLocation);
    }

    public ngOnInit(): void {
        this.activatedRoute.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(data => {
            this.updateContentBase(data as ResolvedRouteData);
        });
    }

    protected updateContentBase(data: ResolvedRouteData): void {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.node = data.content!;

        if (this.platformService.isPlatformServer() && this.node.type === this.nodeTypes.Error && this.node.httpStatuscode !== undefined) {
            this.response?.status(this.node.httpStatuscode);
        }

        if (this.platformService.isPlatformServer()) {
            const etag = this.etagService.getLatestEtag();

            if (etag) {
                this.response?.setHeader('etag', etag.id);
                this.response?.setHeader('last-modified', etag.date.toUTCString());
            }
        }

        this.updateContentDetails(data);

        void this.trackPageInitializedEvent();
    }

    protected abstract updateContentDetails(data: ResolvedRouteData): void;
    protected abstract trackPageInitializedEvent(): Promise<void>;
}
