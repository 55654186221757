import { inject, Injectable } from '@angular/core';
import { type ActivatedRouteSnapshot, type Resolve } from '@angular/router';
import { JsonApiClientService, type ResourceTypes as JsonApiResourceTypes } from '@big-direkt/json-api-client';
import { type SectionLockId, type SectionLockIdentifier, SectionLocksService, type TranslatedPathModel } from '@big-direkt/utils/environment';
import { type NodeModel } from '@big-direkt/utils/shared';
import { type Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ContentMapperService } from './content-mapper.service';
import { NodeInformationService } from './node-information.service';
import { PathTranslatorService } from './path-translator.service';

@Injectable({
    providedIn: 'root',
})
export class ContentResolverService implements Resolve<Observable<NodeModel>> {
    private readonly contentMapper = inject(ContentMapperService);
    private readonly pathTranslator = inject(PathTranslatorService);
    private readonly jsonApiClient = inject(JsonApiClientService);
    private readonly sectionLocksService = inject(SectionLocksService);
    private readonly nodeInformationService = inject(NodeInformationService);

    public resolve(route: ActivatedRouteSnapshot): Observable<NodeModel> {
        const formattedPath = `/${route.pathFromRoot
            .flatMap(value => value.url)
            .map(value => value.path)
            .join('/')}`;

        return this.pathTranslator.translate(formattedPath).pipe(
            switchMap((translatedPath: TranslatedPathModel): Observable<TranslatedPathModel> => {
                const maintenanceTags = translatedPath.maintenanceTags ?? [];

                if (maintenanceTags.length === 0) {
                    return of(translatedPath);
                }

                return this.sectionLocksService.getBySectionIds(maintenanceTags).pipe(
                    map(sectionItems => {
                        if (sectionItems.length === 0) {
                            return translatedPath;
                        }

                        if (maintenanceTags.includes('form_node')) {
                            return this.handleFormNode(sectionItems, translatedPath)
                                ? this.getErrorFromSectionItem(sectionItems['0'], maintenanceTags)
                                : translatedPath;
                        }

                        return this.getErrorFromSectionItem(sectionItems['0'], maintenanceTags);
                    }),
                );
            }),
            switchMap((translatedPath: TranslatedPathModel): Observable<unknown> => {
                const params = this.forwardQueryParams(route);

                return this.jsonApiClient.load(translatedPath.uuid, translatedPath.type as JsonApiResourceTypes, params);
            }),
            map(this.contentMapper.map.bind(this.contentMapper)),
            tap(node => {
                this.nodeInformationService.setNodeType(node.type);
            }),
            shareReplay({
                refCount: true,
            }),
        );
    }

    private forwardQueryParams(route: ActivatedRouteSnapshot): Record<string, number | string> {
        const params: Record<string, number | string> = {};

        const paramRevision = 'revision';
        if (route.queryParamMap.has(paramRevision)) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            params.resourceVersion = `id:${parseInt(route.queryParamMap.get(paramRevision)!, 10)}`;
        }

        const paramExternalToken = 'externalToken';
        if (route.queryParamMap.has(paramExternalToken)) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            params[paramExternalToken] = route.queryParamMap.get(paramExternalToken)!;
        }

        const paramJobOfferId = 'job-offer-id';
        if (route.queryParamMap.has(paramJobOfferId)) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            params[paramJobOfferId] = route.queryParamMap.get(paramJobOfferId)!;
        }

        return params;
    }

    private handleFormNode(sectionItems: SectionLockIdentifier[], translatedPath: TranslatedPathModel): boolean {
        const sectionForFormNodes = sectionItems
            .filter(sectionItem => sectionItem.sectionId === 'form_node');

        return sectionForFormNodes.length > 0 && sectionForFormNodes.filter(sectionItem => sectionItem.formNodeIds.includes(translatedPath.id)).length > 0;
    }

    private getErrorFromSectionItem(sectionItem: SectionLockIdentifier, maintenanceTags: SectionLockId[]): TranslatedPathModel {
        const {
            errorNode: { type, uuid, id },
        } = sectionItem;

        return {
            id,
            type,
            uuid,
            maintenanceTags,
        };
    }
}
